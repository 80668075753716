<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="itemsTable"
      :hide-default-footer="true"
    ></v-data-table>
  </div>
</template>
<script>
import { word } from "@/pdf/advanced-quoter/multi-language.js";
export default {
  name: "PaymentScheme",
  props: {
    packedPriceRule: {
      Object: Object,
      default: null
    },
    language: {
      String: String,
      default: "Spanish"
    }
  },
  data() {
    return {
      items: [],
      headers: [
        { text: "", value: "text", sortable: false },
        { text: "", value: "percent", sortable: false },
        { text: "", value: "description", sortable: false },
        { text: "", value: "amount", sortable: false }
      ],
      itemsTable: [],
      monedaFormatter: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0
      })
    };
  },
  watch: {
    packedPriceRule() {
      this.readData();
    }
  },
  created() {
    this.init();
  },
  methods: {
    word(n) {
      if (this.language == "spanish") return word(n, "spanish");
      else return word(n, "english");
    },
    init() {
      this.readData();
    },
    readData() {
      if (!this.packedPriceRule) {
        this.items = [];
        return 0;
      }
      if (!this.packedPriceRule.seletedPriceRules) {
        this.items = [];
        return 0;
      }
      if (!this.packedPriceRule.finalPrice) {
        this.items = [];
        return 0;
      }
      this.generatePaymentScheme(
        this.packedPriceRule.seletedPriceRules,
        this.packedPriceRule.finalPrice
      );
    },
    //Sacar porcentaje de dos cantidades.
    twoNumbers(A, B) {
      if (!A) return 0;
      if (!B) return 0;
      return ((A / B) * 100).toFixed(2);
    },
    percentValue(value, percent) {
      if (!value) return 0;
      if (!percent) return 0;
      return (value * percent) / 100;
    },
    generatePaymentScheme(rules, price) {
      this.itemsTable = [];
      const currency = this.packedPriceRule.currency
        ? this.packedPriceRule.currency
        : "MXN";
      //Generar el esquema
      const apartPercent = this.twoNumbers(rules.guarantee_deposit, price);
      //Apartado..
      if (rules.guarantee_deposit) {
        this.itemsTable.push({
          text: this.word(44),
          percent: `${apartPercent}%`,
          description: rules.description_guarantee_deposit,
          amount: `${currency} ${this.monedaFormatter.format(
            rules.guarantee_deposit
          )}`
        });
      }
      const deposit = rules.guarantee_deposit ? rules.guarantee_deposit : 0;
      //Enganche..
      if (rules.percentage_down_payment) {
        this.itemsTable.push({
          text: this.word(18),
          percent: `${this.isAnInteger(
            rules.percentage_down_payment - apartPercent
          )}%`,
          description: rules.description_percentage_down_payment,
          amount: `${currency} ${this.monedaFormatter.format(
            this.percentValue(price, rules.percentage_down_payment) - deposit
          )}`
        });
      }
      //Financiamiento..
      if (rules.financing) {
        this.itemsTable.push({
          text: this.word(19),
          percent: `${rules.financing}%`,
          description: rules.description_method_payment,
          amount: `${currency} ${this.monedaFormatter.format(
            this.percentValue(price, rules.financing)
          )}`
        });
      }

      //Mensualidades..
      if (rules.months_agreed && rules.financing && rules.financing != 0) {
        this.itemsTable.push({
          text: `# ${this.word(45)}`,
          percent: rules.months_agreed,
          description: "",
          amount: `${currency} ${this.monedaFormatter.format(
            this.percentValue(price, rules.financing) / rules.months_agreed
          )}`
        });
      }
      //Liquidación..
      if (rules.termination_payment) {
        this.itemsTable.push({
          text: this.word(34),
          percent: `${rules.termination_payment}%`,
          description: rules.description_termination_payment,
          amount: `${currency} ${this.monedaFormatter.format(
            this.percentValue(price, rules.termination_payment)
          )}`
        });
      }
      //Total...
      this.itemsTable.push({
        text: this.word(42),
        percent: `100%`,
        description:
          this.packedPriceRule.iva == 0
            ? ``
            : `${this.word(46)} ${this.packedPriceRule.iva}%`,
        amount: `${currency} ${this.monedaFormatter.format(price)}`
      });
      this.$emit("setpPurchaseScheme", this.itemsTable);
    },
    isAnInteger(num) {
      if (num % 1 == 0) {
        return num;
      } else {
        return num.toFixed(2);
      }
    }
  }
};
</script>
